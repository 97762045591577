<template>
  <div class="line-item-grid line-item">
    <div>
      <v-text-field
        v-model="name"
        label="Item Name"
        placeholder="Name"
        :error-messages="nameErrors"
        required
        @input="$v.name.$touch()"
        @blur="$v.name.$touch()"
      ></v-text-field>
    </div>
    <div>
      <v-text-field v-model="description" label="Description" placeholder="Description"></v-text-field>
    </div>
    <div>
      <v-text-field
        v-model="amount"
        label="Pre-tax amount"
        placeholder="Amount"
        :error-messages="amountErrors"
        required
        @input="$v.amount.$touch()"
        @blur="$v.amount.$touch()"
      ></v-text-field>
    </div>
    <div>
      <v-btn @click="submitItem">add</v-btn>
      <!-- <v-btn @click="cancelItem">discard</v-btn> -->
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  decimal,
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(200),
    },
    amount: {
      required,
      decimal,
    },
  },
  data() {
    return {
      name: "",
      description: "",
      amount: 0,
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.name.minLength &&
        errors.push("Name must be at least 4 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.amount.$dirty) return errors;
      !this.$v.amount.required && errors.push("Amount is required.");
      !this.$v.amount.decimal && errors.push("Only decimal values permitted");
      return errors;
    },
  },
  methods: {
    getId() {
      return uuidv4();
    },
    submitItem() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.$emit("submitItem", {
          name: this.name,
          description: this.description,
          amount: parseInt(this.amount * 100),
          id: this.getId(),
        });
        this.name = "";
        this.description = "";
        this.amount = 0;
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" },
        });
      }
    },
    cancelItem() {
      this.name = "";
      this.description = "";
      this.amount = 0;
      this.$emit("cancelItem");
    },
  },
};
</script>
