<template>
  <v-container>
    <v-card
      v-if="invoice && Object.keys(invoice).length !== 0"
      color="brown lighten-5"
    >
      <v-card-title>
        <v-icon large>mdi-cash-plus</v-icon>View/Edit Invoice
        <v-spacer></v-spacer>
        <StatusDisplay v-if="invoice" :invoice="invoice" showButtons="true" />
        <v-menu dark>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list light>
            <v-list-item @click="deleteInvoice(invoice._id)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setEditing(true)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="editing" @click="setEditing(false)">
              <v-list-item-title>Turn off editing mode</v-list-item-title>
            </v-list-item>
            <v-list-item @click="printInvoice()">
              <v-list-item-title>Print</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>

      <v-card-text>
        <form v-if="clients.length">
          <v-card class="invoice-card">
            <div id="printMe">
              <v-card-title><h1>Invoice</h1></v-card-title>
              <v-card-text>
                <DateDisplay
                  v-if="invoice.date && !loading"
                  :date="invoice.date"
                />

                <p>Invoice #: {{ invoice.invoiceNumber }}</p>

                <ShowContactInfo :prefs="prefs" />

                <ShowClientInfo :client="client" />

                <LineItems v-if="items" :items="items" :editing="editing" />
                <table class="totals-table">
                  <tr>
                    <td><strong>Subtotal</strong></td>
                    <td style="width: 130px">
                      <MoneyFormat :value="subTotal / 100"></MoneyFormat>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tax: </strong> {{ taxRate * 100 }}%
                      {{ taxLabel }} (#{{ taxAccount }})
                    </td>
                    <td>
                      <MoneyFormat :value="taxAmount / 100"></MoneyFormat>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>
                      <MoneyFormat
                        :value="totalAmount / 100"
                        locale="en"
                        currency-code="CAD"
                      ></MoneyFormat>
                    </td>
                  </tr>
                </table>

                <p v-if="prefs.invoiceTerms" class="terms">
                  {{ prefs.invoiceTerms }}
                </p>
                <p class="payment-methods" v-if="prefs.paymentMethods">
                  {{ prefs.paymentMethods }}
                </p>
              </v-card-text>
              <v-card-actions v-if="editing">
                <v-spacer></v-spacer>
                <v-btn small dark color="brown" @click="save">Save</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
import { DateTime } from "luxon";
import MoneyFormat from "vue-money-format";
import LineItems from "@/components/invoice/LineItems";
import DateDisplay from "@/components/invoice/DateDisplay";
import ShowClientInfo from "@/components/client/ShowClientInfo";
import ShowContactInfo from "@/components/invoice/ShowContactInfo";
import StatusDisplay from "@/components/invoice/StatusDisplay";

export default {
  //   mixins: [validationMixin],

  //   validations: {
  //     select: { required },
  //   },
  components: {
    MoneyFormat,
    LineItems,
    DateDisplay,
    ShowClientInfo,
    ShowContactInfo,
    StatusDisplay,
  },
  data() {
    return {
      itemCount: 0,
      select: null,
      taxRate: 0,
      taxLabel: "",
      taxAccount: "",
      statusOptions: ["sent", "received", "draft"],
      hourlyRate: 0,
      dialog: false,
    };
  },
  computed: {
    editing() {
      return this.$store.getters.editing;
    },
    loading() {
      return this.$store.state.loading;
    },
    invoice() {
      return this.$store.getters.invoice;
    },
    items() {
      return this.invoice.items;
    },
    subTotal() {
      let st = 0;
      if (this.items) {
        this.items.forEach((item) => {
          st = st + item.amount;
        });
      }
      return st;
    },
    taxAmount() {
      let ta = 0;
      if (this.taxRate > 0) {
        ta = this.taxRate * this.subTotal;
      }
      return ta;
    },
    totalAmount() {
      let total = 0;
      if (this.subTotal > 0) {
        total = Math.round(this.subTotal + this.taxAmount);
      }
      return total;
    },
    prefs() {
      return this.$store.getters.preferences;
    },
    clients() {
      return this.$store.getters.sortedClients;
    },
    client() {
      let index = this.clients.findIndex(
        (client) => client._id == this.invoice.client
      );
      let client = this.clients[index];
      if (client) {
        return client;
      } else {
        return {};
      }
    },
    // selectErrors() {
    //   const errors = [];
    //   if (!this.$v.select.$dirty) return errors;
    //   !this.$v.select.required && errors.push("Client is required");
    //   return errors;
    // },

    // descriptionErrors() {
    //   const errors = [];
    //   if (!this.$v.name.$dirty) return errors;
    //   !this.$v.name.maxLength &&
    //     errors.push("Description must be at most 255 characters long");
    //   return errors;
    // },
  },

  methods: {
    prettyFileName() {
      let dt = DateTime.fromISO(this.invoice.date);
      let shortDate = dt.toISODate();
      let noSpaceClient = this.invoice.clientName.split(" ").join("_");
      let fileName = `Invoice_${noSpaceClient}_${shortDate}`;
      return fileName;
    },
    setEditing(v) {
      this.$store.dispatch("setEditing", v);
    },
    printInvoice() {
      let pageTitle = document.title;
      document.title = this.prettyFileName();
      this.setEditing(false);
      setTimeout(() => {
        this.$htmlToPaper("printMe", null, () => {
          document.title = pageTitle;
        });
      }, 500);
    },
    deleteInvoice(id) {
      this.$store.dispatch("deleteInvoice", id);
    },
    save() {
      let payload = {
        _id: this.invoice._id,
        items: this.items,
        amount: this.subTotal,
        taxRate: this.taxRate,
        tax: this.taxAmount,
        totalAmount: this.totalAmount,
        date: this.invoice.date,
      };
      this.$store.dispatch("updateInvoice", payload);
    },
    clear() {
      //   this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
    },
  },
  mounted() {
    this.$store.dispatch("loadInvoice", this.$route.params.id);
    this.$store.dispatch("loadClients");
    this.select = this.invoice.client;
    this.taxRate = this.prefs.taxRate || 0;
    this.hourlyRate = this.prefs.hourlyRate || 0;
    this.taxLabel = this.prefs.taxLabel || "";
    this.taxAccount = this.prefs.taxAccount || "";
  },
};
</script>

<style scoped>
.status-draft {
  color: orange;
}
.client-select {
  max-width: 300px;
}
.invoice-card {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  min-height: 1200px;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.terms {
  margin-top: 100px;
  font-weight: bold;
}
.payment-methods {
}
.totals-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}

.totals-table td,
.totals-table th {
  border: 0px solid #ddd;
  padding: 8px;
  text-align: right;
}

.totals-table tr:nth-child(even) {
  /* background-color: #f2f2f2; */
}

.totals-table tr:hover {
  /* background-color: #ddd; */
}
</style>