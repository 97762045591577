<template>
  <div class="hourly-form">
    <div>
      <v-text-field
        v-model="name"
        label="Item Name"
        placeholder="Name"
        :error-messages="nameErrors"
        required
        @input="$v.name.$touch()"
        @blur="$v.name.$touch()"
      ></v-text-field>
    </div>
    <div>
      <v-text-field v-model="description" label="Description" placeholder="Description"></v-text-field>
    </div>
    <div>
      <v-text-field
        v-model="hours"
        label="Number of hours"
        placeholder="Hours"
        :error-messages="hoursErrors"
        required
        @input="$v.hours.$touch()"
        @blur="$v.hours.$touch()"
      ></v-text-field>
    </div>
    <div class="item-amount">Amount: {{amount}}</div>
    <div>
      <v-btn @click="submitItem">add</v-btn>
      <!-- <v-btn @click="cancelItem">discard</v-btn> -->
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { validationMixin } from "vuelidate";

import {
  required,
  minLength,
  maxLength,
  decimal,
} from "vuelidate/lib/validators";
const aboveZero = (value) => value > 0;

export default {
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(200),
    },
    hours: {
      required,
      decimal,
      aboveZero,
    },
  },
  data() {
    return {
      name: null,
      description: null,
      hours: null,
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.name.minLength &&
        errors.push("Name must be at least 4 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    hoursErrors() {
      const errors = [];
      if (!this.$v.hours.$dirty) return errors;
      !this.$v.hours.aboveZero &&
        errors.push("must be a number, and greater than 0");
      !this.$v.hours.required && errors.push("hours field is required.");
      return errors;
    },
    prefs() {
      return this.$store.getters.preferences;
    },
    amount() {
      return this.hours * this.prefs.hourlyRate;
    },
  },
  methods: {
    hourString() {
        return this.hours + "hrs @ $" + this.prefs.hourlyRate + "/hr";
    },
    getId() {
      return uuidv4();
    },
    submitItem() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.$emit("submitItem", {
          name: this.name,
          description: this.description + " (" + this.hourString() + ") ",
          amount: parseInt(this.amount * 100),
          hourlyRate: this.prefs.hourlyRate,
          hours: this.hours,
          id: this.getId(),
        });
        this.name = null;
        this.description = null;
        // this.hours = null;
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" },
        });
      }
    },
    cancelItem() {
      this.name = null;
      this.description = null;
      this.hours = null;
      this.$emit("cancelItem");
    },
  },
};
</script>
<style scoped>
.hourly-form {
  margin-top: 20px;
}
.item-amount {
  font-weight: bold;
  font-size: large;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  text-align: right;
}
</style>