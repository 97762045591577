<template>
  <div>
    <p>
      <strong>
        <span v-if="prefs.companyName">
          {{prefs.companyName}}
          <br />
        </span>
        <span v-if="prefs.firstName">{{prefs.firstName}}</span>
        <span v-if="prefs.lastName">{{prefs.lastName}}</span>
        <br />
      </strong>
      <span v-if="prefs.streetAddress">
        {{prefs.streetAddress}}
        <br />
      </span>
      <span v-if="prefs.city">{{prefs.city}},</span>
      <span v-if="prefs.province">{{prefs.province}},</span>
      <span v-if="prefs.country">
        {{prefs.country}}
        <br />
      </span>
      <span v-if="prefs.postalCode">
        {{prefs.postalCode}}
        <br />
      </span>
      <span v-if="prefs.telephone">{{prefs.telephone}}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: ["prefs"],
};
</script>