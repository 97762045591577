<template>
  <div>
    <p>
      <span v-if="client.name">
        <strong>{{client.name}}</strong>
      </span>
      <br />
      <span v-if="client.contactName">
        {{client.contactName}}
        <br />
      </span>
      <span v-if="client.streetAddress">
        {{client.streetAddress}}
        <br />
      </span>
      <span v-if="client.city">{{client.city}},</span>
      <span v-if="client.province">{{client.province}},</span>
      <span v-if="client.country">
        {{client.country}}
        <br />
      </span>
      <span v-if="client.postalcode">
        {{client.postalCode}}
        <br />
      </span>
      <span v-if="client.phone">
        {{client.phone}}
        <br />
      </span>
      <span v-if="client.email">{{client.email}}</span>
    </p>
  </div>
</template>

<script>
export default {
    props: ["client"],
  data() {
    return {};
  },
};
</script>