<template>
  <div>
    <v-menu
      ref="menustart"
      v-model="menuStart"
      :close-on-content-click="false"
      :return-value.sync="newDate"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <p  class="date" v-on="on">{{prettyDate(date)}}</p>
      </template>
      <v-date-picker v-model="newDate" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menuStart = false">Cancel</v-btn>
        <v-btn text color="primary" @click="changeDate">OK</v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import { DateTime } from "luxon";

export default {
  props: ["date"],
  data() {
    return {
      toggleDatePicker: false,
      newDate: this.dateFromTimestamp(),
      menuStart: null,
    };
  },
  methods: {
    prettyDate(dt) {
      let date = DateTime.fromISO(dt);
      return date.toFormat("LLLL dd, yyyy");
    },
    dateFromTimestamp() {
        let dt = DateTime.fromISO(this.date);
        return dt.toISODate();
    },
    changeDate() {
      this.$refs.menustart.save(this.newDate);
      this.$store.dispatch('changeDate', DateTime.fromISO(this.newDate));
      this.toggleDatePicker = !this.toggleDatePicker;
    },
  },

};
</script>
<style  scoped>
.date {
    cursor: pointer;
    font-weight: bold;
    line-height: 1;
}
.date:hover {
    color: brown;;
}
</style>