<template>
  <div>
    <table id="line-items-table">
      <tr>
        <th>Item</th>
        <th>Description</th>
        <th style="text-align: right">Amount</th>
      </tr>
      <tr v-for="item in items" :key="item._id">
        <td> <a v-if="editing" @click="deleteLineItem(item.id)">X</a> {{ item.name }}</td>
        <td>{{ item.description }}</td>
        <td style="text-align: right">
            
          <MoneyFormat :value="item.amount / 100"></MoneyFormat>
          
        </td>
      </tr>
    </table>
      <v-dialog v-if="editing" v-model="dialog" persistent max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mt-1" small v-bind="attrs" v-on="on">
            Add Line Item
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline"
            >Add {{ showType }} line item</v-card-title
          >
          <v-card-text>
            <div v-if="!isHourly && !isFixed">
              <v-btn
                small
                @click="
                  isFixed = true;
                  isHourly = false;
                "
                >Fixed item</v-btn
              >
              <v-btn
                small
                @click="
                  isHourly = true;
                  isFixed = false;
                "
                >Hourly Item</v-btn
              >
            </div>
            <AddFixedLineItem
              v-if="isFixed"
              @submitItem="addLineItem"
              @cancelItem="cancelLineItem"
            ></AddFixedLineItem>
            <AddHourlyLineItem
              v-if="isHourly"
              @submitItem="addLineItem"
              @cancelItem="cancelLineItem"
            ></AddHourlyLineItem>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" right text @click="closeDialog()"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import AddFixedLineItem from "@/components/invoice/AddFixedLineItem";
import AddHourlyLineItem from "@/components/invoice/AddHourlyLineItem";
import MoneyFormat from "vue-money-format";

export default {
  props: ["items", "editing"],
  components: { AddFixedLineItem, AddHourlyLineItem, MoneyFormat },
  data() {
    return {
      isHourly: false,
      isFixed: false,
      menuStart: null,
      hourlyRate: 0,
      dialog: false,
    };
  },
  computed: {
    showType() {
      let text = "";
      if (this.isHourly) {
        text = "hourly";
      }
      if (this.isFixed) {
        text = "fixed";
      }
      return text;
    },
  },
  methods: {
    addLineItem(item) {
      //   this.items.push(item);
      this.$store.dispatch("addLineItem", item);
      this.isFixed = false;
      this.isHourly = false;
      this.dialog = false;
    },
    cancelLineItem() {
      this.isFixed = false;
      this.isHourly = false;
      this.dialog = false;
    },
    closeDialog() {
      this.isFixed = false;
      this.isHourly = false;
      this.dialog = false;
    },
    deleteLineItem(id) {
      this.$store.dispatch("deleteLineItem", id);
    },
  },
};
</script>

<style scoped>
#line-items-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  margin-top: 100px;
}

#line-items-table td,
#line-items-table th {
  border: 1px solid #ddd;
  padding: 4px;
}

#line-items-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#line-items-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}
.dotted-line-spacer {
  border-bottom: dotted 1px gray;
  flex-grow: 1;
  align-content: flex-end;
}
.line-items-wrapper {
  margin-top: 100px;
}
.add-line-item {
  margin-left: 100px;
}
.line-item-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 100px;
  margin-right: 100px;
}
.item-name {
  font-weight: bold;
  padding-right: 10px;
}
.item-desc {
  font-style: italic;
}
.item-hours {
  font-style: italic;
  padding-left: 10px;
}
.item-amount {
  font-weight: strong;
  display: flex;
  align-self: flex-end;
}
.item-amount a {
  padding-left: 10px;
}
.line-item {
  padding-bottom: 16px;
}

.description {
  font-style: italic;
  color: gray;
}

</style>